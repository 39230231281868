import React from 'react';
import { Link } from 'react-router-dom';

const SecPodcastListItem = () => {
  return (
    <>
      <Link to="/podcast-details" className="podcast-layout-item">
        <div className="podcast-layout-inner">
          <div className="podcast-layout-thumbnail"><img src="assets/img/others/podcast-thumbnail.png" alt="" /></div>
          <div className="podcast-layout-content">
            <h4 className='podcast-title'>How To Build A Health Tech Company That Matters</h4>
            <div className='podcast-text'>Apr 18 • 31 min 21 sec</div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SecPodcastListItem;