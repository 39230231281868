import React from 'react';
import SecStoriesListItem from './SecStoriesListItem';

const SecStoriesList = () => {
  return (
    <>
      <div className='sec-stories-list'>
        <div className='container'>
          <div className='stories-list'>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>
            <SecStoriesListItem/>            
          </div>
        </div>
        <div className="container">
          <div className="stories-action">
            <button type="button" className="btn-action">Load more</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecStoriesList;