import React, { useState} from 'react';
import { Link } from 'react-router-dom';

const SecShowsListItem = ({setListIsHovering}) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
    setListIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
    setListIsHovering(false);
  };

  return (
    <>
      <div className={isHovering ? 'shows-item active' : 'shows-item'} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <Link to="/" className="shows-layout-item">
          <div className="shows-layout-inner">
            <div className="shows-layout-thumbnail">
              <div className='thumbnail-overlay'><i className='fa-solid fa-fw fa-play mr-2'></i> Watch Now</div>
              <img src="assets/img/others/show-thumb-01.png" alt="" />
            </div>
            <div className="shows-layout-content">
              <h4 className='shows-title'>Mark Zuckerberg — Facebook Founder</h4>
              <div className='shows-time'>5 min</div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SecShowsListItem;