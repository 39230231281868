import React from 'react';
import { Link } from 'react-router-dom';

const SearchStory = () => {
  return (
    <>
      <div className="search-stories">
        <div className="search-stories-header">
          <h2 className="search-stories-title">Stories</h2>
          <a className="btn-viewAll" href="/stories">See All</a>
        </div>
        <div className="search-stories-list">
          <div className='search-stories-item'>
            <Link to="/stories-details" className="item-inner">
              <div className='item-thumbnail'><img src='assets/img/others/stories-thumbnail.png' alt=''/></div>
              <div className='item-content'>
                <div className='item-title'>Remote-work boom powers Firstbase to $50M Series B</div>
                <div className='item-text'>Gail Miller</div>
              </div>
            </Link>
          </div>
          <div className='search-stories-item'>
            <Link to="/stories-details" className="item-inner">
              <div className='item-thumbnail'><img src='assets/img/others/stories-thumbnail.png' alt=''/></div>
              <div className='item-content'>
                <div className='item-title'>Remote-work boom powers Firstbase to $50M Series B</div>
                <div className='item-text'>Gail Miller</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchStory;