import React from 'react';

const SecMembershipBanner = () => {
  return (
    <>
      <section className='sec-membership-banner'>
        <div className='container'>
          <div className='banner-content'>
            <h5 className='banner-subtitle'>Fly higher with</h5>
            <h1 className='banner-title'>
              <span>Premium</span>
              <span>Access</span>
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipBanner;