import React from 'react';

const SecFilter = () => {
  return (
    <>
      <div className="page-filter">
        <div className="filter">
          <input type="radio" name='filter' className='filter-radio' defaultChecked/>
          <div className='filter-label'>Most popular</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' className='filter-radio'/>
          <div className='filter-label'>Upload date</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' className='filter-radio'/>
          <div className='filter-label'>Last week</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' className='filter-radio'/>
          <div className='filter-label'>Last month</div>
        </div>
      </div>
    </>
  );
};

export default SecFilter;