import React from 'react';

const SecMembershipFeatures = () => {
  return (
    <>
      <section className='sec-membership-features'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='features-content'> 
                <h2 className='features-title'>THIS IS A BADASS TITLE.</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 offset-lg-6'>
              <div className='features-content'>
                <div className='features-list'>
                  <div className='feature-item'>
                    <div className='item-icon'>
                      <span>1</span>
                      <svg>
                        <circle cx='28' cy='28' r='28'></circle>
                        <circle cx='28' cy='28' r='28'></circle>
                      </svg>
                    </div>
                    <div className='item-content'>
                      <h4 className='item-title'>Lorem ipsum dolor sit amet</h4>
                      <div className='item-text'>
                        <p>Consectetur adipiscing elit. Nunc tempor lectus ac erat vehicula, quis venenatis urna aliquam. Nunc ornare, orci at sodales feugiat, magna metus lacinia leo, id condimentum mauris nunc non dui.</p>
                      </div>
                    </div>
                  </div>
                  <div className='feature-item'>
                    <div className='item-icon'>
                      <span>2</span>
                      <svg>
                        <circle cx='28' cy='28' r='28'></circle>
                        <circle cx='28' cy='28' r='28'></circle>
                      </svg>
                    </div>
                    <div className='item-content'>
                      <h4 className='item-title'>Vivamus auctor tortor</h4>
                      <div className='item-text'>
                        <p>Maximus suspendisse ullamcorper mauris sem, facilisis bibendum eros sollicitudin a. Aliquam volutpat elementum fringilla. Cras rutrum gravida felis vel eleifend.</p>
                      </div>
                    </div>
                  </div>
                  <div className='feature-item'>
                    <div className='item-icon'>
                      <span>3</span>
                      <svg>
                        <circle cx='28' cy='28' r='28'></circle>
                        <circle cx='28' cy='28' r='28'></circle>
                      </svg>
                    </div>
                    <div className='item-content'>
                      <h4 className='item-title'>Sed a nisl eu nisi efficitur luctus</h4>
                      <div className='item-text'>
                        <p>Donec tincidunt nibh lorem, volutpat fermentum lectus viverra ac quisque placerat, ipsum vitae luctus accumsan, nunc arcu dapibus urna, in tristique elit neque id erat.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipFeatures;