import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import DefaultLayout from './layouts/DefaultLayout';
import SearchLayout from './layouts/SearchLayout';
import CtaLessLayout from './layouts/CtaLessLayout';
import BackLayout from './layouts/BackLayout';
import Home from './pages/Home/Home';
import Video from './pages/Video/Video';
import VideoDetails from './pages/Video/VideoDetails';
import Shows from './pages/Video/Shows';
import Podcast from './pages/Podcast/Podcast';
import PodcastDetails from './pages/Podcast/PodcastDetails';
import Stories from './pages/Story/Stories';
import StoriesDetails from './pages/Story/StoriesDetails';
import Search from './pages/Search/Search';
import SearchResult from './pages/Search/SearchResult';
import Membership from './pages/Membership/Membership';

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<DefaultLayout />}>
            <Route path='/' exact element={<Home />} />
          </Route>
          <Route path='/video' element={<DefaultLayout />}>
            <Route path='/video' exact element={<Video />} />
          </Route>
          <Route path='/video-details' element={<BackLayout />}>
            <Route path='/video-details' exact element={<VideoDetails/> } />
          </Route>
          <Route path='/shows' element={<BackLayout />}>
            <Route path='/shows' exact element={<Shows/> } />
          </Route>
          <Route path='/podcast' element={<DefaultLayout />}>
            <Route path='/podcast' exact element={<Podcast/> } />
          </Route>
          <Route path='/podcast-details' element={<BackLayout />}>
            <Route path='/podcast-details' exact element={<PodcastDetails/> } />
          </Route>
          <Route path='/stories' element={<DefaultLayout />}>
            <Route path='/stories' exact element={<Stories/> } />
          </Route>
          <Route path='/stories-details' element={<BackLayout />}>
            <Route path='/stories-details' exact element={<StoriesDetails/> } />
          </Route>
          <Route path='/search' element={<SearchLayout />}>
            <Route path='/search' exact element={<Search/> } />
          </Route>
          <Route path='/search-result' element={<SearchLayout />}>
            <Route path='/search-result' exact element={<SearchResult/> } />
          </Route>
          <Route path='/membership' element={<CtaLessLayout/> }>
            <Route path='/membership' exact element={<Membership/> } />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;