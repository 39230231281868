import React, {useState} from 'react';
import SecFilter from '../../../components/SecFilter';
import SecGridListSwitch from '../../../components/SecGridListSwitch';
import SecStoriesGrid from './SecStoriesGrid';
import SecStoriesList from './SecStoriesList';

const SecAllStories = () => {
  const [isGrid, setIsGrid] = useState(true);
  return (
    <>
      <section className='sec-stories'>
        <div className='container d-flex align-items-center justify-content-between'>
          <SecFilter/>
          <SecGridListSwitch setIsGrid={setIsGrid}/>
        </div>
        {
          isGrid?<SecStoriesGrid/>:<SecStoriesList/>
        }
      </section>
    </>
  );
};

export default SecAllStories;