import React from 'react';
import SecVideoList from './SecVideoList';
import SecLiveVideoList from './SecLiveVideoList';
import SecFilter from '../../../components/SecFilter';

const SecAllVideo = () => {
  return (
    <>
      <section className='sec-videos'>
        <div className='container'>
          <SecFilter/>
        </div>
        <SecVideoList/>
        <SecVideoList/>
        <SecLiveVideoList/>
        <div className="video-action">
          <button type="button" className="btn-action">Load More</button>
        </div>
      </section>
    </>
  );
};

export default SecAllVideo;