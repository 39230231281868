import React, { useEffect, useState, checkActive } from 'react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import Authentication from './Authentication';
import Registration from './Registration';

const DefaultHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authenticationActive, setAuthenticationActive] = useState(false);
  const [registrationActive, setRegistrationActive] = useState(false);

  // useEffect(() => {
  //   var body = document.body;
  //   var scrollUp = 'scroll-up';
  //   var scrollDown = 'scroll-down';
  //   var lastScroll = 0;
  //   window.addEventListener('scroll', function () {
  //     var currentScroll = window.pageYOffset;
  //     if (currentScroll <= 0) {
  //       body.classList.remove(scrollUp);
  //       return;
  //     }
  //     if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
  //       body.classList.remove(scrollUp);
  //       body.classList.add(scrollDown);
  //     } else if (
  //       currentScroll < lastScroll &&
  //       body.classList.contains(scrollDown)
  //     ) {
  //       body.classList.remove(scrollDown);
  //       body.classList.add(scrollUp);
  //     }
  //     lastScroll = currentScroll;
  //   });
  // }, [])
  return (
    <>
      <Authentication authenticationActive={authenticationActive} setAuthenticationActive={setAuthenticationActive}/>
      <Registration registrationActive={registrationActive} setRegistrationActive={setRegistrationActive}/>
      <header>
        <div className="container">
          <div className="brand">
            <a className="logo" href="/"><img src="assets/img/logo.svg" alt="CEO Logo" /></a>
          </div>
          <div className="navigation">
            <div className="nav-menu">
              <div className="nav-item"><NavLink to="/" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Home</NavLink></div>
              <div className="nav-item"><NavLink to="/video" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Video</NavLink></div>
              <div className="nav-item"><NavLink to="/podcast" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Podcast</NavLink></div>
              <div className="nav-item"><NavLink to="/stories" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Stories</NavLink></div>
              <div className="nav-item"><NavLink to="/search" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="fa-regular fa-fw fa-search"></i></NavLink></div>
            </div>
          </div>
          <div className="header-options">
            <button type="button" className="btn-action btn-login" onClick={() => setAuthenticationActive(true)}>Login</button>
            <button type="button" className="btn-action btn-membership" onClick={() => navigate('/membership')}>Membership</button>
          </div>
        </div>
      </header>
      {location.pathname === '/' &&
        <section className="header-filler"></section>
      }
    </>
  );
};

export default DefaultHeader;