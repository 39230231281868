import React from 'react';
import SecAllVideo from './Components/SecAllVideo';
import SecVideoBanner from './Components/SecVideoBanner';
import './Css/Video.css';

const Video = () => {
  return (
    <>
      <SecVideoBanner/>
      <SecAllVideo/>
    </>
  );
};

export default Video;