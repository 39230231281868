import React from 'react';
import SecVideoList from './Components/SecVideoList';
import SecVideoPlay from './Components/SecVideoPlay';
import './Css/VideoDetails.css';

const VideoDetails = () => {
  return (
    <>
      <SecVideoPlay/>
      <section className='sec-videos'>
        <SecVideoList/>
        <SecVideoList/>
      </section>
    </>
  );
};

export default VideoDetails;