import React, { useState } from 'react';

const Authentication = ({authenticationActive, setAuthenticationActive}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isForgotToggle, setIsForgotToggle] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const switchType = event => {
    setIsPasswordVisible(current => !current);
  };
  const closePanel = event => {
    setAuthenticationActive(false);
    setIsPasswordVisible(false);
    setIsForgotToggle(false);
  }
  const forgotToggle = event => {
    setIsForgotToggle(current => !current);
  }
  return (
    <>
      <div className={"authentication-panel " + (authenticationActive ? 'active' : '')}>
        <div className="panel-backdrop"></div>
        <div className="panel-content">
          <div className="panel-header">
            <div className="panel-title">Welcome back</div>
            <button type="button" className="panel-close" onClick={closePanel}><i className="fa-light fa-fw fa-times"></i></button>
          </div>
          <div className="panel-body">
            <form className={"form-content " + ((email && password) ? 'active' : '')} id="authentication-form">
              <div className="form-group">
                <div className={"input-style " + (email ? 'hasValue' : '')}>
                  <input type="text" className="form-control" name='email' autoComplete='new-email' value={email} onChange={(e) => setEmail(e.target.value)} />
                  <div className="float-label">Email</div>
                </div>
              </div>
              <div className="form-group">
                <div className={"input-style " + (password ? 'hasValue' : '')}>
                  <input type={(isPasswordVisible?'text':'password')} className="form-control" name='password' autoComplete='new-password' value={password} onChange={(e) => setPassword(e.target.value)} />
                  <button type='button' className={'btn-password ' + (isPasswordVisible?'active':'')} onClick={switchType}><i className={'fa-light fa-fw ' + (isPasswordVisible?'fa-eye':'fa-eye-slash')}></i></button>
                  <div className="float-label">Password</div>
                </div>
              </div>
              <div className="form-group form-action">
                <button type="button" className="btn-action">Login</button>
              </div>
              <div className="form-options">
                <button type='button' className="option-link" onClick={forgotToggle}>Forgot Password?</button>
              </div>
            </form>
          </div>
        </div>
        <div className={"panel-content forgot-panel " + (isForgotToggle?'active' : '')}>
          <div className="panel-header">
            <div className="panel-title">Redefine password</div>
            <div className="panel-text">Confirm your email so we can send you a link to reset your password.</div>
            <button type="button" className="panel-back" onClick={forgotToggle}><i className="fa-light fa-fw fa-chevron-left"></i></button>
          </div>
          <div className="panel-body">
            <form className={"form-content " + (forgotEmail ? 'active' : '')} id="forgot-form">
              <div className="form-group">
                <div className={"input-style " + (forgotEmail ? 'hasValue' : '')}>
                  <input type="text" className="form-control" name='forgotEmail' autoComplete='new-email' value={forgotEmail} onChange={(e) => setForgotEmail(e.target.value)} />
                  <div className="float-label">Email</div>
                </div>
              </div>
              <div className="form-group form-action">
                <button type="button" className="btn-action">Send Link</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Authentication;