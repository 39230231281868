import React from 'react';

const SecMembershipMembers = () => {
  return (
    <>
      <section className='sec-membership-members'>
        <div className='container-fluid'>
          <div className='membership-members'>
            <div className='member'>
              <div className='member-image'><img src='assets/img/peoples/image-ceo-01.png' alt=''/></div>
            </div>
            <div className='member'>
              <div className='member-image'><img src='assets/img/peoples/image-ceo-02.png' alt=''/></div>
            </div>
            <div className='member'>
              <div className='member-image'><img src='assets/img/peoples/image-ceo-03.png' alt=''/></div>
            </div>
            <div className='member'>
              <div className='member-image'><img src='assets/img/peoples/image-ceo-04.png' alt=''/></div>
            </div>
            <div className='member'>
              <div className='member-image'><img src='assets/img/peoples/image-ceo-05.png' alt=''/></div>
            </div>
            <div className='member'>
              <div className='member-image'><img src='assets/img/peoples/image-ceo-06.png' alt=''/></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipMembers;