import React, {useState} from 'react';
import SecStoriesDetailsBanner from './Components/SecStoriesDetailsBanner';
import SecStoriesDetailsContent from './Components/SecStoriesDetailsContent';
import SecStoriesSlider from './Components/SecStoriesSlider';
import './Css/StoriesDetails.css';

const StoriesDetails = () => {
  const [isListen, setIsListen] = useState(false);
  return (
    <>
      <SecStoriesDetailsBanner isListen={isListen} setIsListen={setIsListen}/>
      <SecStoriesDetailsContent isListen={isListen}/>
      <SecStoriesSlider/>
    </>
  );
};

export default StoriesDetails;