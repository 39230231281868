import React from 'react';
import { Link } from 'react-router-dom';

const SecStoriesDetailsContent = ({isListen}) => {
  return (
    <>
      <section className='sec-stories-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 offset-lg-6'>
              {
                isListen?
                <div className='stories-audio-container'>Audio Placeholder</div>
                :''
              } 
              <div className='stories-details-content'>
                <div className='stories-details-text'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet augue facilisis arcu pellentesque condimentum vitae et nisi. Mauris id turpis a lacus placerat luctus vel sit amet nulla. Duis ac posuere dolor. Fusce egestas aliquet neque, quis tempus ligula pellentesque id. Nunc vehicula consectetur ultricies. Integer condimentum augue orci, eget efficitur nunc aliquet consectetur.</p>
                  <p>Maecenas risus mauris, finibus ut ipsum sed, efficitur pretium nulla. Ut euismod vitae eros a vulputate. Duis pharetra facilisis finibus.</p>
                  <p>Curabitur tempus sagittis laoreet. Suspendisse vel ante eleifend, cursus libero et, hendrerit justo. Ut tristique porttitor erat, venenatis luctus lectus fermentum bibendum. Phasellus molestie tempor ligula id ullamcorper. Cras in justo turpis.Sed dapibus ex sit amet ultrices pulvinar. Integer et eros sed mauris dignissim facilisis quis iaculis ipsum. Nunc faucibus sodales cursus.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='stories-details-content'>
                <figure className='stories-details-image'>
                  <div className='image-container'><img src='assets/img/others/image-stories-details.png' alt=''/></div>
                  <figcaption>Lorem ipsum dolor – Sit amet 2022.</figcaption>
                </figure>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='stories-details-content'>
                <h2 className='stories-details-title'>Curabitur luctus, ipsum vitae commodo egestas</h2>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='stories-details-content'>
                <div className='stories-details-text'>
                  <p>Maecenas congue ultricies tortor, quis lacinia enim sodales et. Proin elementum maximus velit, et vulputate diam fermentum nec. Proin efficitur nunc urna, quis eleifend metus laoreet at. Mauris eget tortor diam. Donec facilisis euismod velit, at ultricies massa laoreet et. Cras pellentesque, metus sed efficitur varius, odio urna fringilla arcu, vitae convallis dolor enim vel nisl.</p>
                  <p>Nulla vitae rutrum odio. Sed sollicitudin purus rutrum, consequat lacus ut, tristique neque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam porttitor justo at nisi sodales, ac semper metus fermentum. Proin egestas, leo nec tempor congue, nisi massa rhoncus dolor, quis sollicitudin neque neque elementum nulla. </p>
                  <p>Fusce pellentesque, augue a finibus aliquam, orci justo tincidunt nunc, quis fermentum velit arcu sed velit. Praesent eu lectus elit. Nunc faucibus nibh mattis orci commodo varius. In hac habitasse platea dictumst. Nullam vehicula, eros vitae semper dapibus, lorem quam pulvinar felis, nec sodales nulla justo sollicitudin justo.</p>
                  <p>Ut tempor turpis eu massa facilisis, in ornare risus eleifend. Curabitur luctus, ipsum vitae commodo egestas, elit augue tempus nulla, ut scelerisque dui ex id massa. Etiam gravida non lorem non finibus. Aliquam pretium vulputate risus, eu fermentum felis. Sed ac odio magna. Quisque erat quam, accumsan id pellentesque eu, pretium id eros. Phasellus sit amet diam posuere, posuere elit in, facilisis massa. Suspendisse nec rhoncus orci. Nulla varius diam at porttitor tempus.</p>
                </div>
                <div className='stories-share'>
                  <div className='share-title'>Share</div>
                  <div className='share-list'>
                    <Link to="/" className='share-item'><i className='fa-regular fa-fw fa-link'></i></Link>
                    <Link to="/" className='share-item'><i className='fa-brands fa-fw fa-facebook-f'></i></Link>
                    <Link to="/" className='share-item'><i className='fa-brands fa-fw fa-twitter'></i></Link>
                    <Link to="/" className='share-item'><i className='fa-regular fa-fw fa-envelope'></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecStoriesDetailsContent;