import React from 'react';
import { useNavigate  } from 'react-router-dom';
import SearchFilter from './Components/SearchFilter';
import SearchVideo from './Components/SearchVideo';
import SearchPodcast from './Components/SearchPodcast';
import SearchStory from './Components/SearchStory';
import './Css/SearchResult.css';

const SearchResult = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className='sec-search-result'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-1 col-lg-2 col-xl-3'>
              <button type='button' className='btn-back' onClick={() => navigate(-1)}><i className='fa-light fa-fw fa-chevron-left'></i></button>
            </div>
            <div className='col-md-11 col-lg-10 col-xl-9'>
              <div className='search-result-content'>
                <h1 className='search-query'>gail miller</h1>
                <SearchFilter/>
                <SearchVideo/>
                <SearchPodcast/>
                <SearchStory/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchResult;