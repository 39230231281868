import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

const SecVideoPlay = () => {
  return (
    <>
      <section className='section-video-play'>
        <div className='container'>
          <div className='video-content'>
            <h1 className='video-title'>Facebook Founder Mark Zuckerberg</h1>
            <div className='video-text'>
              <p>In January 2020, Facebook founder and CEO Mark Zuckerberg traveled to Salt Lake City, Utah for an interview with Clint Betts at Silicon Slopes Tech Summit.</p>
            </div>
            <div className='video-share'>
              <div className='share-title'>Share</div>
              <div className='share-list'>
                <Link to="/" className='share-item'><i className='fa-regular fa-fw fa-link'></i></Link>
                <Link to="/" className='share-item'><i className='fa-brands fa-fw fa-facebook-f'></i></Link>
                <Link to="/" className='share-item'><i className='fa-brands fa-fw fa-twitter'></i></Link>
                <Link to="/" className='share-item'><i className='fa-regular fa-fw fa-envelope'></i></Link>
              </div>
            </div>
          </div>
          <div className='video-container'>
            <ReactPlayer url='https://vimeo.com/108459920' />
          </div>
        </div>
      </section>
    </>
  );
};

export default SecVideoPlay;