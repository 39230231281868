import React from 'react';
import SecAllStories from './Components/SecAllStories';
import SecStoriesBanner from './Components/SecStoriesBanner';
import './Css/Stories.css';

const Stories = () => {
  return (
    <>
      <SecStoriesBanner/>
      <SecAllStories/>
    </>
  );
};

export default Stories;