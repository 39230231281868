import React from 'react';
import { Outlet  } from 'react-router-dom';
import CallToAction from '../components/CallToAction';
import BackHeader from '../shared/BackHeader';
import DefaultFooter from '../shared/DefaultFooter';

const BackLayout = () => {
  return (
    <>
      <BackHeader/>
      <Outlet />
      <CallToAction/>
      <DefaultFooter />
    </>
  );
};

export default BackLayout;