import React from 'react';
import SecFilter from '../../../components/SecFilter';
import SecPodcastList from './SecPodcastList';
import SecPodcastListAlt from './SecPodcastListAlt';

const SecAllPodcast = () => {
  return (
    <>
      <section className='sec-podcast'>
        <div className='container'>
          <SecFilter/>
        </div>
        <SecPodcastList/>
        <SecPodcastListAlt/>
        <div className="podcast-action">
          <button type="button" className="btn-action">Load More</button>
        </div>
      </section>
    </>
  );
};

export default SecAllPodcast;