import React from 'react';
import { Link } from 'react-router-dom';

const SearchPodcast = () => {
  return (
    <>
      <div className="search-podcast">
        <div className="search-podcast-header">
          <h2 className="search-podcast-title">Podcasts</h2>
          <a className="btn-viewAll" href="/podcast">See All</a>
        </div>
        <div className="search-podcast-list">
          <div className='search-podcast-item'>
            <Link to="/podcast-details" className="item-inner">
              <div className='item-thumbnail'><img src='assets/img/others/podcast-thumbnail.png' alt=''/></div>
              <div className='item-content'>
                <div className='item-title'>Podcast Placeholder</div>
              </div>
            </Link>
          </div>
          <div className='search-podcast-item'>
            <Link to="/podcast-details" className="item-inner">
              <div className='item-thumbnail'><img src='assets/img/others/podcast-thumbnail.png' alt=''/></div>
              <div className='item-content'>
                <div className='item-title'>Podcast Placeholder</div>
              </div>
            </Link>
          </div>
          <div className='search-podcast-item'>
            <Link to="/podcast-details" className="item-inner">
              <div className='item-thumbnail'><img src='assets/img/others/podcast-thumbnail.png' alt=''/></div>
              <div className='item-content'>
                <div className='item-title'>Podcast Placeholder</div>
              </div>
            </Link>
          </div>
          <div className='search-podcast-item'>
            <Link to="/podcast-details" className="item-inner">
              <div className='item-thumbnail'><img src='assets/img/others/podcast-thumbnail.png' alt=''/></div>
              <div className='item-content'>
                <div className='item-title'>Podcast Placeholder</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPodcast;