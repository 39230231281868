import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import './Css/Search.css';

const Search = () => {
  const [search, setSearch] = useState('');
  const resetValue = event => {
    setSearch(current => '');
  }
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/search-result`; 
    navigate(path);
  }
  return (
    <>
      <section className='sec-search'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <form className="form-content" id="search-form">
                <div className="form-group">
                  <div className={"input-style " + (search ? 'hasValue' : '')}>
                    <input type="text" className="form-control" name='search' autoComplete='new-search' value={search} onChange={(e) => setSearch(e.target.value)} />
                    <button type='button' className='input-reset' onClick={resetValue}><i className='fa-light fa-fw fa-times'></i></button>
                    <div className="float-label">Search on CEO</div>
                  </div>
                  <div className={'search-suggestion ' + (search ? 'active' : '')}>
                    <div className='suggestion-list'>
                      <Link to="/search-result" className='suggestion-item'>
                        <div className='item-icon'><i className='fa-solid fa-fw fa-play'></i></div>
                        <div className='item-content'>
                          <div className='item-title'>Summit Backstage: Gail Miller</div>
                          <div className='item-text'>Found on Videos</div>
                        </div>
                      </Link>
                      <Link to="/search-result" className='suggestion-item'>
                        <div className='item-icon'><i className='fa-solid fa-fw fa-microphone'></i></div>
                        <div className='item-content'>
                          <div className='item-title'>Gail Miller On Politics In The Workplace</div>
                          <div className='item-text'>Found on Podcasts</div>
                        </div>
                      </Link>
                      <Link to="/search-result" className='suggestion-item'>
                        <div className='item-icon'><i className='fa-solid fa-fw fa-newspaper'></i></div>
                        <div className='item-content'>
                          <div className='item-title'>Remote-work boom powers Firstbase to $50M Series B</div>
                          <div className='item-text'>Found on Stories</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className={'search-action ' + (search ? 'active' : '')}>
                    <button type='button' className='btn-action' onClick={routeChange}><i className='fa-light fa-fw fa-search mr-2'></i>Search on CEO</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Search;