import React from 'react';
import { Link } from 'react-router-dom';

const SecPodcastListAltItem = () => {
  return (
    <>
      <Link to="/podcast-details" className="podcast-layout-item">
        <div className="podcast-layout-inner">
          <div className="podcast-layout-thumbnail">
            <div className="podcast-image">
              <div className="podcast-image-content">
                <div className="podcast-image-title">THE CLINT BETTS SHOW</div>
                <div className="podcast-image-number">#7</div>
              </div>
              <div className="podcast-image-container">
                <img src="assets/img/peoples/people-10.png" alt="" />
              </div>
            </div>
          </div>
          <div className="podcast-layout-content">
            <h4 className='podcast-title'>Liz Wiseman — Leadership & Polictics In The Workplace</h4>
            <div className='podcast-text'>Apr 18 • 31 min 21 sec</div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SecPodcastListAltItem;