import React from 'react';
import SecPodcastListAltItem from './SecPodcastListAltItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SecPodcastListAlt = () => {
  return (
    <>
      <div className="podcast-layout">
        <div className="container">
          <div className="podcast-layout-header">
            <h2 className="podcast-layout-title">Clint Betts Show</h2>
            <a className="btn-viewAll" href="/">View on Spotify</a>
          </div>
        </div>
        <div className="podcast-layout-list podcast-alt">
          <Swiper
            spaceBetween={10}
            slidesPerView={3}
          >
            <SwiperSlide>
              <SecPodcastListAltItem/>
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListAltItem/>
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListAltItem/>
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListAltItem/>
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListAltItem/>
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListAltItem/>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SecPodcastListAlt;