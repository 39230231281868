import React from 'react';
import SecLiveVideoListItem from './SecLiveVideoListItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SecLiveVideoList = () => {
  return (
    <>
      <div className="video-layout">
        <div className="container">
          <div className="video-layout-header">
            <h2 className="video-layout-title">Live now</h2>
            <a className="btn-viewAll" href="/">View playlist</a>
          </div>
        </div>
        <div className="video-layout-list live">
          <Swiper
            spaceBetween={20}
            slidesPerView={2.2}
          >
            <SwiperSlide>
              <SecLiveVideoListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecLiveVideoListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecLiveVideoListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecLiveVideoListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecLiveVideoListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecLiveVideoListItem />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SecLiveVideoList;