import React, {useState} from 'react';
import SecMembershipBanner from './Components/SecMembershipBanner';
import SecMembershipFeatures from './Components/SecMembershipFeatures';
import SecMembershipMembers from './Components/SecMembershipMembers';
import SecMembershipDetails from './Components/SecMembershipDetails';
import SecMembershipBenefit from './Components/SecMembershipBenefit';
import './Css/Membership.css';

const Membership = () => {
  const sectionData = [
    { 
      name: 'Videos', 
      text: 'Donec tincidunt nibh lorem, volutpat fermentum lectus viverra ac quisque placerat, ipsum vitae luctus accumsan, nunc arcu dapibus urna, in tristique elit neque id erat.', 
      icon: 'play', 
      type: 'Free for all',
      image: 'assets/img/others/membership-video.png',
      bgClass: 'bg-shades07',
    },
    { 
      name: 'Podcasts', 
      text: 'Donec tincidunt nibh lorem, volutpat fermentum lectus viverra ac quisque placerat, ipsum vitae luctus accumsan, nunc arcu dapibus urna, in tristique elit neque id erat.', 
      icon: 'microphone', 
      type: 'Free and Premium versions',
      image: 'assets/img/others/membership-podcast.png',
      bgClass: 'bg-shades06',
    },
    { 
      name: 'Stories', 
      text: 'Donec tincidunt nibh lorem, volutpat fermentum lectus viverra ac quisque placerat, ipsum vitae luctus accumsan, nunc arcu dapibus urna, in tristique elit neque id erat.', 
      icon: 'newspaper', 
      type: 'Free and Premium versions',
      image: 'assets/img/others/membership-stories.png',
      bgClass: 'bg-shades08',
    },
    { 
      name: 'Schedule', 
      text: 'Donec tincidunt nibh lorem, volutpat fermentum lectus viverra ac quisque placerat, ipsum vitae luctus accumsan, nunc arcu dapibus urna, in tristique elit neque id erat.', 
      icon: 'calendar', 
      type: 'Premium only',
      image: 'assets/img/others/membership-schedule.png',
      bgClass: 'bg-shades07',
    },
  ];

  return (
    <>
      <SecMembershipBanner/>
      <SecMembershipMembers/>
      <SecMembershipFeatures/>
      {sectionData.map((item, index) => (
        <SecMembershipDetails key={index} icon={item.icon} name={item.name} text={item.text} type={item.type} image={item.image} bgClass={item.bgClass} />
      ))}
      <SecMembershipBenefit/>
    </>
  );
};

export default Membership;