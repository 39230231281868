import React from 'react';
import { Link } from 'react-router-dom';

const DefaultFooter = () => {
  return (
    <>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="footer-copyright">Copyright © 2022 CEO. All rights reserveds.</div>
          </div>
          <div className="col-md-6">
            <div className='footer-link'>
              <div className='item'>
                <Link className="link" to="/">Help</Link>
              </div>
              <div className='item'>
                <Link className="link" to="/">Terms of use</Link>
              </div>
              <div className='item'>
                <Link className="link" to="/">Privacy policy</Link>
              </div>
              <div className='item'>
                <Link className="link" to="/">Cookies</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
    
  );
};

export default DefaultFooter;