import React from 'react';
import { Link } from 'react-router-dom';
import SecShowsList from './Components/SecShowsList';
import './Css/Shows.css';

const Shows = () => {
  
  return (
    <>
      <section className='section-shows'>
        <div className='container'>
          <div className='shows-content'>
            <h1 className='shows-title'>The CEO Show</h1>
            <div className='shows-text'>
              <p>A conversation on leadership and community. Hosted by Clint Betts, The CEO.com Show airs every Tuesday at 8 AM ET.</p>
            </div>
            <div className='shows-share'>
              <div className='share-title'>Share</div>
              <div className='share-list'>
                <Link to="/" className='share-item'><i className='fa-regular fa-fw fa-link'></i></Link>
                <Link to="/" className='share-item'><i className='fa-brands fa-fw fa-facebook-f'></i></Link>
                <Link to="/" className='share-item'><i className='fa-brands fa-fw fa-twitter'></i></Link>
                <Link to="/" className='share-item'><i className='fa-regular fa-fw fa-envelope'></i></Link>
              </div>
            </div>
          </div>
          <div className='shows-container'>
            <SecShowsList/>
          </div>
        </div>
      </section>
    </>
  );
};

export default Shows;