import React from 'react';
import SecStoriesSliderItem from './SecStoriesSliderItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SecStoriesSlider = () => {
  return (
    <>
      <div className='stories-slider'>
        <Swiper
          spaceBetween={10}
          slidesPerView={4}
        >
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
          <SwiperSlide>
            <SecStoriesSliderItem/>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default SecStoriesSlider;