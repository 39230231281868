import React, {useState} from 'react';

const SecGridListSwitch = ({setIsGrid}) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = event => {
    setIsActive(current => !current);
    setIsGrid(current => !current);
  };
  return (
    <>
      <div className='gl-switch'>
        <button type='button' className={!isActive ? 'btn-switch active' : 'btn-switch'} onClick={handleClick}>
          <span className="fa-rotate-270 d-inline-block mr-2">
            <i className="fa-solid fa-fw fa-objects-column fa-flip-vertical"></i>
          </span>
          Grid View
        </button>
        <button type='button' className={isActive ? 'btn-switch active' : 'btn-switch'} onClick={handleClick}>
          <i className="fa-solid fa-fw fa-chart-simple fa-rotate-90 mr-2"></i>
          List View
        </button>
      </div>
    </>
  );
};

export default SecGridListSwitch;