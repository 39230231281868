import React from 'react';
import { useNavigate  } from 'react-router-dom';

const BackHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <header className='header-alt'>
        <div className="container">
          <button type='button' className='btn-back' onClick={() => navigate(-1)}><i className='fa-light fa-fw fa-chevron-left'></i></button>
        </div>
      </header>
    </>
  );
};

export default BackHeader;