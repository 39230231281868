import React from 'react';
import { Link } from 'react-router-dom';

const SecStoriesListItem = () => {
  return (
    <>
      <Link to="/stories-details" className="stories-item">
        <div className='item-image'><img src='assets/img/others/image-stories-01.png' alt=''/></div>
        <div className='item-content'>
          <h4 className='item-title'>Remote-work boom powers Firstbase to $50M Series B</h4>
        </div>
      </Link>
    </>
  );
};

export default SecStoriesListItem;