import React from 'react';
import SecStoriesSlider from './SecStoriesSlider';
import { Link } from 'react-router-dom';

const SecStoriesGrid = () => {
  return (
    <>
      <div className='container px-0'>
        <div className="stories-grid">
          <div className="stories-layout stories-layout-1">
            <div className="container">
              <Link to="/stories-details" className="stories-layout-inner">
                <div className="stories-image"><img src="assets/img/others/image-stories-01.png" alt="" /></div>
                <div className="stories-content">
                  <h2 className="stories-title">Remote-work boom powers Firstbase to $50M Series B</h2>
                </div>
              </Link>
            </div>
          </div>
          <div className="stories-layout stories-layout-2">
            <div className="container">
              <Link to="/stories-details" className="stories-layout-inner">
                <div className="stories-content">
                  <div className="stories-image"><img src="assets/img/others/image-stories-02.png" alt="" /></div>
                  <h2 className="stories-title">Consectetur adipisci ng elit integer mollis metus</h2>
                </div>
              </Link>
            </div>
          </div>
          <div className="stories-layout stories-layout-3">
            <div className="container">
              <Link to="/stories-details" className="stories-layout-inner">
                <div className="stories-content">
                  <div className="stories-image"><img src="assets/img/others/image-stories-03.png" alt="" /></div>
                  <h2 className="stories-title">Fenean lobortis erat eget risus pulvinar vehicula proin at eleifend</h2>
                </div>
              </Link>
            </div>
          </div>
          <div className="stories-layout stories-layout-4">
            <Link to="/stories-details" className="stories-layout-inner">
              <div className="stories-image"><img src="assets/img/others/image-stories-video.png" alt=""/></div>
              <div className="stories-content">
                <div className="container">
                  <h2 className="stories-title">Donec blandit sem rutrum tortor mattis malesuada pellentesque</h2>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <SecStoriesSlider/>
      <div className="container">
        <div className="stories-action">
          <button type="button" className="btn-action">Load more</button>
        </div>
      </div>
    </>
  );
};

export default SecStoriesGrid;