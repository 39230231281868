import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const SecPodcastBanner = () => {
  return (
    <>
      <section className="sec-podcast-banner">
        <Swiper
          modules={[ Pagination, EffectFade ]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          effect="fade"
        >
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/podcast-details" className="podcast">
                <div className="container">
                  <div className="podcast-image">
                    <div className="podcast-image-content">
                      <div className="podcast-image-title">THE CLINT BETTS SHOW</div>
                      <div className="podcast-image-number">#7</div>
                    </div>
                    <div className="podcast-image-container">
                      <img src="assets/img/peoples/people-10.png" alt=""/>
                    </div>
                  </div>
                  <div className="podcast-content">
                    <h1 className="podcast-title">Bassam Salem — Imposter Syndrome, Leadership Credibility, and U.S. Immigration With Atlas RTX CEO</h1>
                    <div className="podcast-time">31 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/podcast-details" className="podcast">
                <div className="container">
                  <div className="podcast-image">
                    <div className="podcast-image-content">
                      <div className="podcast-image-title">THE CLINT BETTS SHOW</div>
                      <div className="podcast-image-number">#7</div>
                    </div>
                    <div className="podcast-image-container">
                      <img src="assets/img/peoples/people-10.png" alt=""/>
                    </div>
                  </div>
                  <div className="podcast-content">
                    <h1 className="podcast-title">Bassam Salem — Imposter Syndrome, Leadership Credibility, and U.S. Immigration With Atlas RTX CEO</h1>
                    <div className="podcast-time">31 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/podcast-details" className="podcast">
                <div className="container">
                  <div className="podcast-image">
                    <div className="podcast-image-content">
                      <div className="podcast-image-title">THE CLINT BETTS SHOW</div>
                      <div className="podcast-image-number">#7</div>
                    </div>
                    <div className="podcast-image-container">
                      <img src="assets/img/peoples/people-10.png" alt=""/>
                    </div>
                  </div>
                  <div className="podcast-content">
                    <h1 className="podcast-title">Bassam Salem — Imposter Syndrome, Leadership Credibility, and U.S. Immigration With Atlas RTX CEO</h1>
                    <div className="podcast-time">31 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
};

export default SecPodcastBanner;