import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SecMembershipBenefit = () => {
  return (
    <>
      <section className='sec-membership-benefit'>
        <div className='container'>
          <div className='membership-benefit-content'>
            <h2 className='membership-benefit-title'>ONLY MEMBERS RECIEVE</h2>
          </div>
          <div className='membership-benefit-slider'>
            <Swiper
              spaceBetween={20}
              slidesPerView={5}
            >
              <SwiperSlide>
                <Link to="/" className="benefit-item">
                  <div className="benefit-item-inner">
                    <div className="benefit-item-thumbnail"><img src="assets/img/others/video-thumb-01.png" alt="" /></div>
                    <div className="benefit-item-content">
                      <div className='benefit-item-icon'>
                        <span>1</span>
                        <svg>
                          <circle cx='28' cy='28' r='28'></circle>
                          <circle cx='28' cy='28' r='28'></circle>
                        </svg>
                      </div>
                      <h4 className='benefit-item-title'>Access to the CEO.com leadership platform</h4>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipBenefit;