import React, {useState} from 'react';
import SecShowsListItem from './SecShowsListItem';

const SecShowsList = () => {
  const [listIsHovering, setListIsHovering] = useState(false);

  return (
    <>
      <div className={listIsHovering ? 'shows-list hovered' : 'shows-list'}>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>
        <SecShowsListItem setListIsHovering={setListIsHovering}/>      
      </div>
    </>
  );
};

export default SecShowsList;