import React from 'react';
import SecPodcastListItem from './SecPodcastListItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SecPodcastList = () => {
  return (
    <>
      <div className="podcast-layout">
        <div className="container">
          <div className="podcast-layout-header">
            <h2 className="podcast-layout-title">CEO Originals</h2>
            <a className="btn-viewAll" href="/">View on Spotify</a>
          </div>
        </div>
        <div className="podcast-layout-list">
          <Swiper
            spaceBetween={10}
            slidesPerView={6}
          >
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
            <SwiperSlide>
              <SecPodcastListItem />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SecPodcastList;