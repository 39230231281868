import React from 'react';
import SecAllPodcast from './Components/SecAllPodcast';
import SecPodcastBanner from './Components/SecPodcastBanner';
import './Css/Podcast.css';

const Podcast = () => {
  return (
    <>
      <SecPodcastBanner/>
      <SecAllPodcast/>
    </>
  );
};

export default Podcast;