import React from 'react';
import { Link } from 'react-router-dom';

const SecPodcastPlay = () => {
  return (
    <>
      <section className='section-podcast-play'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='podcast-content'>
                <h1 className='podcast-title'>Bassam Salem — Imposter Syndrome, Leadership Credibility, and U.S. Immigration</h1>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='podcast-content'>
                <div className='podcast-player'></div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='podcast-options'>
                <div className='podcast-share'>
                  <div className='share-title'>Share</div>
                  <div className='share-list'>
                    <Link to="/" className='share-item'><i className='fa-regular fa-fw fa-link'></i></Link>
                    <Link to="/" className='share-item'><i className='fa-brands fa-fw fa-facebook-f'></i></Link>
                    <Link to="/" className='share-item'><i className='fa-brands fa-fw fa-twitter'></i></Link>
                    <Link to="/" className='share-item'><i className='fa-regular fa-fw fa-envelope'></i></Link>
                  </div>
                </div>
                <div className='podcast-service'>
                  <div className='service-title'>Also avaliable on</div>
                  <div className='service-list'>
                    <Link to="/" className='service-item'><img src='assets/img/podcast-provider-01.svg' alt=''/></Link>
                    <Link to="/" className='service-item'><img src='assets/img/podcast-provider-02.svg' alt=''/></Link>
                    <Link to="/" className='service-item'><img src='assets/img/podcast-provider-03.svg' alt=''/></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecPodcastPlay;