import React from 'react';
import { Link } from 'react-router-dom';

const SecLiveVideoListItem = () => {
  return (
    <>
      <Link to="/video-details" className="video-layout-item">
        <div className="video-layout-inner">
          <div className="video-layout-thumbnail">
            <div className='video-live'>Live</div>
            <img src="assets/img/others/live-video-thumb-01.png" alt="" />
          </div>
          <div className="video-layout-content">
            <h4 className='video-title'>Steve Ballmer & Senator Mitt Romney</h4>
            <div className='video-text'>12k watching</div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SecLiveVideoListItem;