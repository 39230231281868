import React from 'react';
import { Link } from 'react-router-dom';

const SecVideoListItem = () => {
  return (
    <>
      <Link to="/video-details" className="video-layout-item">
        <div className="video-layout-inner">
          <div className="video-layout-thumbnail"><img src="assets/img/others/video-thumb-01.png" alt="" /></div>
          <div className="video-layout-content">
            <h4 className='video-title'>Gail Miller</h4>
            <div className='video-text'>5 min</div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SecVideoListItem;