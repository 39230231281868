import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SearchVideo = () => {
  return (
    <>
      <div className="search-video">
        <div className="search-video-header">
          <h2 className="search-video-title">Videos</h2>
          <a className="btn-viewAll" href="/video">See All</a>
        </div>
        <div className="search-video-list">
          <Swiper
            spaceBetween={20}
            slidesPerView={4}
          >
            <SwiperSlide>
              <div className="search-video-item">
                <Link to="/video-details" className="item-inner">
                  <div className="item-thumbnail"><img src="assets/img/others/video-thumb-01.png" alt="" /></div>
                  <div className="item-content">
                    <h4 className='item-title'>Gail Miller</h4>
                    <div className='item-text'>5 min</div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="search-video-item">
                <Link to="/video-details" className="item-inner">
                  <div className="item-thumbnail"><img src="assets/img/others/video-thumb-01.png" alt="" /></div>
                  <div className="item-content">
                    <h4 className='item-title'>Gail Miller</h4>
                    <div className='item-text'>5 min</div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="search-video-item">
                <Link to="/video-details" className="item-inner">
                  <div className="item-thumbnail"><img src="assets/img/others/video-thumb-01.png" alt="" /></div>
                  <div className="item-content">
                    <h4 className='item-title'>Gail Miller</h4>
                    <div className='item-text'>5 min</div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="search-video-item">
                <Link to="/video-details" className="item-inner">
                  <div className="item-thumbnail"><img src="assets/img/others/video-thumb-01.png" alt="" /></div>
                  <div className="item-content">
                    <h4 className='item-title'>Gail Miller</h4>
                    <div className='item-text'>5 min</div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="search-video-item">
                <Link to="/video-details" className="item-inner">
                  <div className="item-thumbnail"><img src="assets/img/others/video-thumb-01.png" alt="" /></div>
                  <div className="item-content">
                    <h4 className='item-title'>Gail Miller</h4>
                    <div className='item-text'>5 min</div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="search-video-item">
                <Link to="/video-details" className="item-inner">
                  <div className="item-thumbnail"><img src="assets/img/others/video-thumb-01.png" alt="" /></div>
                  <div className="item-content">
                    <h4 className='item-title'>Gail Miller</h4>
                    <div className='item-text'>5 min</div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SearchVideo;