import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const SecVideoBanner = () => {
  return (
    <>
      <section className="sec-video-banner">
        <Swiper
          modules={[ Pagination, EffectFade ]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          effect="fade"
        >
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/" className="video">
                <div className="video-image"><img src="assets/img/others/video-slider-01.png" alt="" /></div>
                <div className="container">
                  <div className="video-content">
                    <h1 className="video-title">Facebook Founder Mark Zuckerberg</h1>
                    <div className="video-time">47 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/" className="video">
                <div className="video-image"><img src="assets/img/others/video-slider-01.png" alt="" /></div>
                <div className="container">
                  <div className="video-content">
                    <h1 className="video-title">Facebook Founder Mark Zuckerberg</h1>
                    <div className="video-time">47 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/" className="video">
                <div className="video-image"><img src="assets/img/others/video-slider-01.png" alt="" /></div>
                <div className="container">
                  <div className="video-content">
                    <h1 className="video-title">Facebook Founder Mark Zuckerberg</h1>
                    <div className="video-time">47 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
};

export default SecVideoBanner;