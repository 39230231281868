import React from 'react';
import { Link } from 'react-router-dom';

const SecStoriesSliderItem = () => {
  return (
    <>
      <Link to="/stories-details" className="stories-content">
        <div className="stories-image"><img src="assets/img/others/stories-slider-item.png" alt=""/></div>
        <h2 className="stories-title">Proin malesuada felis felis, in sagittis mauris fringilla sed</h2>
      </Link>
    </>
  );
};

export default SecStoriesSliderItem;