import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Registration = ({registrationActive,setRegistrationActive}) => {
  const [submitView, setSubmitView] = useState(false);
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [terms, setTerms] = useState(false);


  const closePanel = event => {
    setRegistrationActive(false);
    setSubmitView(false);
    setFname('');
    setLname('');
    setEmail('');
    setPhone('');
    setCompany('');
    setRole('');
    setTerms(false);
  }

  const switchPanel = event => {
    setSubmitView(true);
  }
  return (
    <>
      <div className={"registration-panel " + (registrationActive ? 'active' : '')}>
        <button type="button" className="panel-close" onClick={closePanel}><i className="fa-light fa-fw fa-times"></i></button>
        {
          submitView?(
          <>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="registration-success-content">
                  <div className='registration-success-icon'><i className='fa-light fa-fw fa-check'></i></div>
                  <h1 className="registration-success-title">Well done!<br/>Now it's up to us.</h1>
                  <div className="registration-success-text">
                    <p>We have received your request and our team is already evaluating your profile. Now just wait for us to inform you by email.</p>
                  </div>
                  <div className="registration-success-action">
                    <button type='button' className="btn-action" onClick={closePanel}>Okay!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='registration-image'><img src='assets/img/brand.svg' alt=''/></div>
          </>
          )
          :
          (
          <>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="registration-content">
                  <h1 className="registration-title">The first step of a great journey.</h1>
                  <div className="registration-text">
                    <p>Please fill in the fields on the side, this will allow us to get to know you better.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <form className={"registration-form " + ((fname && lname && email && phone && terms) ? 'active' : '')} id="registration-form">
                  <div className="form-group">
                    <div className={"input-style " + (fname ? 'hasValue' : '')}>
                      <input type="text" className="form-control" name='fname' value={fname} onChange={(e) => setFname(e.target.value)}/>
                      <div className="float-label">First name*</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className={"input-style " + (lname ? 'hasValue' : '')}>
                      <input type="text" className="form-control" name='lname' value={lname} onChange={(e) => setLname(e.target.value)}/>
                      <div className="float-label">Last name*</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className={"input-style " + (email ? 'hasValue' : '')}>
                      <input type="email" className="form-control" name='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                      <div className="float-label">E-mail*</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className={"input-style " + (phone ? 'hasValue' : '')}>
                      <input type="tel" className="form-control" name='phone' value={phone} onChange={(e) => setPhone(e.target.value)}/> 
                      <div className="float-label">Phone number*</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className={"input-style " + (company ? 'hasValue' : '')}>
                      <input type="text" className="form-control" name='company' value={company} onChange={(e) => setCompany(e.target.value)}/> 
                      <div className="float-label">Company name</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className={"input-style " + (role ? 'hasValue' : '')}>
                      <input type="text" className="form-control" name='role' value={role} onChange={(e) => setRole(e.target.value)}/> 
                      <div className="float-label">Role</div>
                    </div>
                  </div>
                  <div className="form-options">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" name='terms' checked={terms} onChange={(e) => setTerms(!terms)}/>
                      <div className="form-check-label">I agree with the <Link to="/" target="_blank">terms of use</Link> and <Link to="/" target="_blank">privacy policy</Link>.</div>
                    </div>
                  </div>
                  <div className="form-group form-action"> 
                    <button type="button" className="btn-action" onClick={switchPanel}>Send request</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          </>
          )
        }
      </div>
    </>
  );
};

export default Registration;