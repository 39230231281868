import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const SecStoriesBanner = () => {
  return (
    <>
      <section className="sec-stories-banner">
        <Swiper
          modules={[ Pagination, EffectFade ]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ el: '.swiper-pagination', type: 'bullets', clickable: true }}
          effect="fade"
        >
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/stories-details" className="stories">
                <div className="stories-image"><img src="assets/img/others/stories-slider-01.png" alt="" /></div>
                <div className="container">
                  <div className="stories-content">
                    <h1 className="stories-title">VR Technology Is Transforming E-Commerce Significantly</h1>
                    <div className="stories-time">7 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/stories-details" className="stories">
                <div className="stories-image"><img src="assets/img/others/stories-slider-01.png" alt="" /></div>
                <div className="container">
                  <div className="stories-content">
                    <h1 className="stories-title">VR Technology Is Transforming E-Commerce Significantly</h1>
                    <div className="stories-time">7 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <Link to="/stories-details" className="stories">
                <div className="stories-image"><img src="assets/img/others/stories-slider-01.png" alt="" /></div>
                <div className="container">
                  <div className="stories-content">
                    <h1 className="stories-title">VR Technology Is Transforming E-Commerce Significantly</h1>
                    <div className="stories-time">7 min</div>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className='container'>
          <div className='swiper-pagination'></div>
        </div>
      </section>
    </>
  );
};

export default SecStoriesBanner;