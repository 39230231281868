import React, {useState} from 'react';

const SecStoriesDetailsBanner = ({setIsListen}) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = event => {
    setIsActive(current => !current);
    setIsListen(current => !current);
  };
  return (
    <>
      <section className='sec-stories-details-banner'>
        <div className='banner-image'><img src='assets/img/others/stories-slider-01.png' alt=''/></div>
        <div className='container'>
          <div className='banner-content'>
            <h1 className='banner-title'>VR Technology Is Transforming E-Commerce Significantly</h1>
            <div className='banner-action'>
            {
              isActive?<button type='button' className='btn-action alt' onClick={handleClick}><i className="fa-light fa-fw fa-waveform-lines mr-2"></i> Listening</button>:<button type='button' className='btn-action' onClick={handleClick}><i className="fa-light fa-fw fa-headphones mr-2"></i> Listen the content</button>
            }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecStoriesDetailsBanner;